//阅读理解与完形填空
<template>
  <div class='reading-comprehension'>
    <div class="boxs"  v-if='this.value.analysis==1'> <div class="top">
      <!-- 答题时间 -->
      <div class='time'>
        <div class="img">
          <img src="../assets/time.png" alt="">
        </div>
        <p>{{str}}</p>
      </div>
      <!-- 题目序号 -->
      <div class='Title_number'>
        <div class="img">
          <img src="../assets/Title_number.png" alt="">
        </div>
        <p>1/1</p>
      </div>
      <!-- 答题卡 -->
      <div class='Answer_sheet' @click='answerSheet'>
        <div class="img">
          <img src="../assets/Answer_sheet.png" alt="">
        </div>
        <p>答题卡</p>
      </div>
      <!-- 收藏 -->
      <div class='Collection' @click='collection'>
        <div class="img">
          <img src="../assets/Collection.png" alt="" v-if='collections==false'>
          <img src="../assets/Collections.png" alt="" v-if='collections==true'>
        </div>
        <p>收藏题目</p>
      </div>
    </div>
     <div class='div'></div></div>
     <!-- 题目 -->
     <div class="contents"
     :style="{ paddingBottom: heighta }" v-html='value.content'>
     </div>
     <!-- 答案 -->
     <div class='answer'
      :style="{
        height: height,
        maxHeight: maxHeight,
        minHeight: '50px',
        marginBottom:0,
        padding:0
      }">
       <div class="tb"  @touchmove="mouns">
         <img src="../assets/tb.png" alt="">
       </div>
       <div class="top">
          <span>{{index+1}}</span>/{{value.questions.length}}
       </div>
       <v-touch id='option' :style="{ height: height }" @swipeleft='swipeleft' @swiperight='swiperight'  v-if='this.value.analysis==1'>
        <p class='title'>{{value.questions[this.index].title}}</p> 
         <div class="box">   
          <div class='single'  @click='choice(item.option)'  v-for="item in value.questions[this.index].option"   v-bind:key="item.id">
           <div class="left"  :class="{'lefts':answers==item.option}">
             <span>{{item.option}}</span>
           </div>
           <p>{{item.text}}</p> 
         </div>
      </div> 

       </v-touch >
              <v-touch id='option' :style="{ height: height }" @swipeleft='swipeleft' @swiperight='swiperight' v-if='this.value.analysis==0'>
        <p class='title'>{{value.questions[this.index].title}}</p> 
         <div class="box">   
          <div class='single'  v-for="item in value.questions[this.index].option"   v-bind:key="item.id">
           <div class="left"  :class="{'lefts':answers==item.option}">
             <span>{{item.option}}</span>
           </div>
           <p>{{item.text}}</p> 
         </div>
      </div> 
      
  <!-- 解析 -->
    <div class="analysis" v-if='this.value.analysis==0'>
       <!-- 卡片 -->
       <div class="card"  v-if='this.value.analysis==0'>
         <p>
           <span style="color:#2775FF">正确答案是<span v-for='item in this.value.questions[this.index].answer' v-bind:key="item">{{item}},</span> </span>
           <span style="color:#FF3B3B">您的答案是<span v-for='item in this.value.questions[this.index].user_answer' v-bind:key="item">{{item}},</span></span>
         </p>
         <div class='distribution'>
           <div class='time'>
             <p>答题时间</p>
             <p>{{value.questions[this.index].user_answer_time}}</p>
           </div>
           <div class='accuracy'>
             <p>全站正确率</p>
             <p>{{accuracy}}%</p>
           </div>
           <div class='fallibility'>
             <p>易错项</p>
             <p>{{value.questions[this.index].error_prone}}</p>
           </div>
         </div>
       </div>
       <!-- 解析 -->
       <div class="text">
         <div>
           <p>本题出自</p>
           <span >{{this.value.from_to}}</span>
         </div>
         <div>
          <p>本题考点</p>
          <span>{{value.questions[this.index].examination_points}}</span>
         </div>
         <div>
          <p>本题解析</p>
          <span  v-html='value.questions[this.index].analysis'></span>
         </div>
       </div>
    </div>
       </v-touch>
     </div>
     <!-- 答题卡 -->
    <div class='Answersheet' v-if='Answersheet==1'>
    <div class="top">
      <div>
        <i class='i'></i>
        <span>已做</span>
      </div>
      <div>
        <i></i>
        <span>未做</span>
      </div>
    </div>
    <div class="bottom"> 
      <div @click='fanhui(index)' class="red"  v-for='item,index in this.answer' v-bind:key="item.id"  :class="item.answer_result==2?'red':'blue'" >
        {{index+1}}
      </div>
    </div>
    <div class="btn" @click='Answersheets(0)'>
      交卷查看结果
    </div>
  </div>
  </div>
</template>

<script>
import request from "../plugin/request";
export default {
  
  props:{
    msg: String,
      value:{}
    },
   data() {
    return {
      collections:false,
      Answersheet:0,
      hour:0,//定义时，分，秒，毫秒并初始化为0；

        minute:0,

ms:0,

second:0,//秒

        time:'',

str:'',
times:0,
      answers:false,
      index:0,
      height: "",
      first: true,
      heighta: "",
      maxHeight: "",
      answer:'',
      accuracy:''
    };
  },
  mounted() {
          this.timeStart()
                this.showAccuracy()

        this.a()
    this.$nextTick(() => {
      this.a()
      this.timeStart()
            this.showAccuracy()

    })
        this.a()
        this.record()

  },  
  methods: {
    async showAccuracy() {
       const data=await request.post('/app/exam/showAccuracy',{
         question_id:this.value.questions[this.index].id
       })
       console.log(data)
       this.accuracy=data.data.accuracy/100
    },
            //做题记录
    async record() {
      const data=await request.post('/app/exam/showAnalysis',{
         question_id:this.value.questions[this.index].id,
        record_id:this.value.record_id,
         stem_id:this.value.id,
      })
      console.log(data)
      this.answers=data.data.question[0].questions[this.index].user_answer
    },
    // 答题结果
    Answersheets(type) {
      let u = navigator.userAgent;
      //android终端或者uc浏览器
      let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1;
      //ios终端
      let isiOS = !!u.match(/(i[^;]+;( U;)? CPU.+Mac OS X)/);
      if(isiOS){
      // 假设方法名为： JSMessageName；postMessage里面可以传参数
          window.webkit.messageHandlers.questionresult.postMessage({
            id:this.value.record_id,
            type:type
          })
      }else if(isAndroid){
          //安卓相关操作
          window.android.questionresult(this.value.record_id,type); 
      }
    },
     refreshData() {
      this.a();
      this.timeStart()
      this.showAccuracy()
    },
    a() {
      if(this.value.analysis==0) {
          this.answers=this.value.questions[this.index].answer
      }
      console.log(this.value.is_collect)
    if(this.value.is_collect==1) {
      console.log(25)
         this.collections=true
      }
      
    },
    mouns(e) {
      let heights = document.documentElement.clientHeight;
      let pageY = e.changedTouches[0].clientY;
      this.height = heights - pageY + "px";
      this.heighta = heights - pageY + 50 + "px";
      this.maxHeight = heights - 100 + "px";
      e.preventDefault();
    },
    swipeleft() {
       this.record()
      if(this.index<this.value.questions.length-1) {
        this.index=this.index+1
      } else {
        this.index=this.value.questions.length-1
      }
        this.reset()
        this.timeStart()
        },
    swiperight() {
       this.record()
      if(this.index==0) {
        this.index=0
      } else {
        this.index=this.index-1
      }
        this.reset()
        this.timeStart()
    },
  // 开始计时
  timeStart(){
   
this.time =setInterval(this.timer,100)
// this.tiems=this.times+1
},

timer () {//定义计时函数
        this.ms =this.ms +50        //毫秒

        if (this.ms >=1000) {
          this.times=this.times+1

          this.ms =0

          this.second =this.second +1        //秒
        }

if (this.second >=60) {

this.second =0

          this.minute =this.minute +1        //分钟

        }

if (this.minute >=60) {

this.minute =0

          this.hour =this.hour +1        //小时

        }

this.str =this.toDub(this.hour) +':' +this.toDub(this.minute) +':' +this.toDub(this.second)/*+""+this.toDubms(this.ms)+"毫秒"*/

        // document.getElementById('mytime').innerHTML=h+"时"+m+"分"+s+"秒"+ms+"毫秒";
      },

toDub (n) {//补0操作

if (n <10) {

return '0' + n

}else {

return '' + n

}

},
stop () {


clearInterval(this.time)

},

reset () {//重置

        clearInterval(this.time)
        this.times=0

        this.hour =0

        this.minute =0

        this.ms =0

        this.second =0

        this.str ='00:00:00'

      },

    // 单选
    async choice(answer) {
       this.answers=[answer]
       const parame={
         question_id:this.value.questions[this.index].id,
         stem_id:this.value.id,
         answer:this.answers,
         record_id:this.value.record_id,
         answer_time:this.times
       }
       const data=await request.post('/app/exam/submitAnswers',parame)
       
       if(data.code==200) {
         console.log(1)
        if(this.index<this.value.questions.length-1) {
         this.swipeleft()
         this.answers=''
         this.reset()
         this.timeStart()
      } else {
        if(this.value.analysis!=0) {
            this.answerSheet()
        }
      
         this.answers=''
         this.reset()
      }


       }

    },
    // 收藏
    async collection() {
      const data =await request.post('/app/exam/collect',{
        stem_id:this.value.id
      })
      if(data.code==200) {
        this.collections=!this.collections
      }
    },
    // 查看答题卡
    async answerSheet() {
      this.Answersheet=1
      const {data}=await request.post('/app/exam/answerSheet',{record_id:this.value.record_id})
      console.log(data)
      this.answer=data.answer

    },
    fanhui(index) {

      this.Answersheet=0
      this.index=index
               this.reset()
         this.timeStart()
               this.record()

    }

}
}
</script>

<style  scoped  lang='scss'>
.reading-comprehension {

  width: 100%;
  height: 100%;
  padding-bottom: .8rem;
    .boxs {
  width: 100%;
  height: 1.84rem;
  position: relative;
  top: 0;
  background-color: #fff;

    .top {
    width: 100%;
    height: 1.71rem;
    background-color: #fff;
    padding:0  .53rem;
    box-sizing: border-box;
    display: -webkit-flex; /* Safari */
    display: flex;
    flex-direction:row;
    flex-wrap:nowrap;
    justify-content: space-between;
    // margin-bottom:.13rem;
    
    div {
      width:33%;

      .img {
        width: 100%;
        height: 1.23rem;
        position: relative;

        img {
          position: absolute;
          top:50%;
          left: 50%;
          transform: translate(-50%,-50%);
        }
      
      }

      p {
        width: 100%;
        text-align: center;
        font-size: .24rem;
        color: #585A5B;
      }
    }
    .time {
      img {
        width: .53rem;
        height: .55rem;
      }
    }
    .Title_number {
      img {
        width: .56rem;
        height: .45rem;
      }
    }

    .Answer_sheet {
      img {
        width: .52rem;
        height: .55rem;
      }
    }

    .Collection {
      img {
        width: .55rem;
        height: .53rem;
      }
    }


  }
  .div {
    width: 100%;
    height: .13rem;
    background-color: #ebeff2;
    padding:0 ;
    margin:0
  }
}
  
  
  .contents {
    font-size: .32rem;
    line-height: 1.5;
    padding:.13rem .85rem 0 .53rem;
    margin-bottom: 200px;
    box-sizing: border-box;
  }

  .answer {
    position: fixed;
    bottom: 0;
    background-color: #fff;
    height:2.67rem;
    width: 100%;
    border-top: .01rem solid  #EBEFF2;

    .tb {
       width:1.63rem ;
       height: .69rem;
       position: absolute;
       left: 50%;
       transform: translateX(-50%);

       img {
         width: 100%;
         height: 100%;
         position: relative;
         top:-50%
       }
    }

    .top {
      box-sizing: border-box;
      width: 100%;
      height: .84rem;
      line-height: .84rem;
      text-align: end;
      padding-right: .4rem;
      font-size: .32rem;
      border-bottom: .01rem solid   #EBEFF2;
      color: #585A5B ;

      span {
        color: #2775FF;
      }
    }
  // .v-touch {
  //   touch-action: pan-y !important;
  //   overflow: scroll  !important;
  // }

   #option {
     touch-action: pan-y !important;
     padding:0 0 .65rem 0 ;
     box-sizing: border-box;
     width: 100%;
     height: 100%;
     overflow: scroll;
     .title {
       width: 100%;
       padding:.20rem .59rem .20rem .51rem;
       box-sizing: border-box;
       
     }
     .box {
       width: 100%;
      padding:0 .59rem .20rem .51rem ;
      box-sizing: border-box;

     }

    .single {
      width: 100%;
      margin-bottom:.67rem;
      margin-top:.57rem;
      height: .91rem;
      line-height: .95rem;
      p {
        height:  .91rem ;
        display: inline-block;
        line-height: .95rem;
        bottom: 0;
        padding: 0;
      }

      .left {
        width: .91rem;
        height: .91rem;
        border: .03rem solid #2775FF;
        border-radius: 50%;
        box-sizing: border-box;
        text-align: center;
        display: inline-block;
        margin-right: .4rem;
        line-height: .91rem;

         span {
           font-size: .43rem;
           color: #2775FF;
         }
      }
      .lefts {
        width: .91rem;
        height: .91rem;
        border-radius: 50%;
        background-color:#2775FF;
        text-align: center;
        display: inline-block;
        margin-right: .4rem;
         span {
           font-size: .43rem;
           color: #fff;
           line-height: .91rem ;
         }
      }
    }

 .analysis {
    border-top: .01rem solid #EBEFF2;
    width: 100%;
    padding:0 .85rem .4rem .53rem;
    box-sizing: border-box;

    .card {
      width: 8.67rem;
      height: 2.93rem;
      background-color: #EBEFF2;
      border-radius: .13rem;
      margin-top: .52rem;
      margin-left: .05rem;
      padding-top: .40rem;
      box-sizing: border-box;
      
     
     p {
       width: 100%;
       text-align: center;
       font-size: .37rem;
       margin-bottom: .50rem;
     }

         .distribution {
      width: 100%;
      display: flex;

      div {
        width: 33%;
        p {
          margin-bottom:.28rem;
          font-size:.32rem ;
          color: #585A5B;
        }
      }
    }
    }

    .text {
      margin-top: .53rem;

      p {
        font-size: .37rem;
        margin-bottom: .30rem;
        font-weight: 500;
      }

      span {
        display: block;
        font-size: .32rem;
        margin-bottom: .4rem;
         font-weight: 300;
      }
    }





  }
  }
  }

  .Answersheet {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    background-color: #fff;
    .top {
  width: 100%;
  text-align:end;
  div {
    display: inline-block;
    margin-right: .4rem;
    margin-top:.4rem ;
    color:#A3A3A3 ;
    .i {
      background-color: #2775FF;
    }
   i {
     width: .2rem;   
     height: .2rem;
     display: inline-block;
     background-color: #B5B5B5;
     border-radius: 50%;
     margin-right:.04rem ;
   }
    span {
      font-size: .27rem;
    }
  }
}
.bottom {
  margin-top: .4rem;
  width: 100%;
  padding:0 .77rem;
  box-sizing: border-box;
  flex: center;
  div:nth-child(5n) {
    margin-right: 0;

  }
  .red {
    width: 1.08rem;
    height: 1.08rem;
    display: inline-block;
    text-align: center;
    line-height: 1.08rem;
    border-radius: 50%;
    font-size: .43rem;
    color: #fff;
    margin-right: .76rem;
    margin-bottom: .67rem;
    background-color:#B5B5B5
  }
  .blue {
    width: 1.08rem;
    height: 1.08rem;
    display: inline-block;
    text-align: center;
    line-height: 1.08rem;
    background-color: #2775FF;
    border-radius: 50%;
    font-size: .43rem;
    color: #fff;
    margin-right: .76rem;
    margin-bottom: .67rem;
  }
}
.btn  {
  width:3.92rem ;
  height: 1.09rem;
  border:1px solid #2775FF;
  text-align: center;
  line-height: 1.09rem;
  color:#2775FF;
  border-radius: .13rem;
  font-size: .4rem;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-top: 1.33rem;
}
  }


}

</style>